<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <router-link
                    class="btn btn-primary mr-1"
                    :to="{ name: 'ProductStockRequestCreate' }"
                    >Create</router-link
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <v-data-table
                :headers="headers"
                :items="productStockRequest"
                item-key="id"
                :search="search"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="elevation-1"
                @page-count="pageCount = $event"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                v-else
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.created_by_name }}</td>
                    <td>{{ row.item.sender_name }}</td>
                    <td>{{ row.item.receiver_name }}</td>
                    <td>{{ row.item.note }}</td>
                    <td>{{ row.item.status_name }}</td>
                    <td>{{ row.item.created_at }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'ProductStockRequestShow',
                          params: { id: row.item.id },
                        }"
                        class="badge badge-primary mr-1"
                      >
                        Show
                      </router-link>
                      <router-link
                        :to="{
                          name: 'ProductStockRequestEdit',
                          params: { id: row.item.id },
                        }"
                        v-if="row.item.status == 'Sedang di proses'"
                        class="badge badge-warning mr-1"
                        >Edit</router-link
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <v-pagination
                    v-model="page"
                    :length="lastPage"
                    :total-visible="itemsPerPage"
                    @input="getData"
                  ></v-pagination>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
// import swal from "sweetalert";

export default {
  name: 'ProductStockRequest',
  data() {
    return {
      search: '',
      page: 1,
      lastPage: 0,
      pageCount: 0,
      itemsPerPage: 10,
      name: 'Product Stock Request',
      productStockRequest: [],
      loading: false,
      sortBy: 'created_at',
      sortDesc: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      axios
        .get(this.$store.state.api + 'stock_request', {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            page: this.page,
          },
        })
        .then((result) => {
          this.loading = false;
          this.productStockRequest = result.data.data.data;
          this.itemsPerPage = result.data.data.per_page;
          this.lastPage = result.data.data.last_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Request By',
          value: 'created_by_name',
        },
        {
          text: 'Sender',
          value: 'sender_name',
        },
        {
          text: 'Receiver',
          value: 'receciver_name',
        },
        {
          text: 'Note',
          value: 'note',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Created At',
          value: 'created_at',
        },
        { text: 'Action' },
      ];
    },
  },
};
</script>
